import styled from 'styled-components';
import { media } from '../../../../utils/device';

export const StandardButtonSmallStyles = styled.button`
  background: #4765ff;
  border-radius: 20px;
  color: #ffffff;
  border: 0;
  padding: 4px 18px 4px 18px;
  font-size: 14px;
  height: 25px;
  cursor: pointer;

  ${media.tabletS} {
    width: 80px;
    font-size: 14px;
    height: 26px;
  }

  ${media.mobileL} {
    width: 90px;
    font-size: 16px;
    height: 26px;
  }

  ${media.mobileM} {
    width: 80px;
    font-size: 14px;
    height: 26px;
  }

  ${media.mobileS} {
    width: 80px;
    font-size: 14px;
    height: 26px;
  }
`;

import React from 'react';

// Interfaces
interface Module {
  module_id: string;
  type: string;
  next: string;
  altNext: string;
  prev: string;
}

// Utils
import { ModuleTypes } from '../../utils/moduleTypes';

// Components
import CollectionDateForm from '../modules/CollectionDateForm';
import ThankYouForm from '../modules/ThankYouForm';
import PatientPortalFormContainer from '../PatientPortalFormContainer';

export const findNextModule = (stateModules: Array<Module>, step: number): string => {
  const nextModule = stateModules.find((item: Module) => {
    // We have made it to the last module, show last module
    if (!item.next) {
      return item;
    }

    // Otherwise, fetch next module
    if (item.next === stateModules[step]?.module_id) {
      return item;
    }
  });

  if (!nextModule) {
    return ModuleTypes.default;
  }
  return nextModule.type;
};

export const getModulesDispatcher = ({
  step,
  onPrevStep,
  onNextStep,
  activationCode,
  setActivationCode,
}: {
  step: number;
  onPrevStep: () => void;
  onNextStep: () => void;
  activationCode: string;
  setActivationCode: React.Dispatch<React.SetStateAction<string>>;
}): { [key: string]: JSX.Element } => {
  return {
    [ModuleTypes.activationCodeCollection]: (
      <PatientPortalFormContainer activationCode={activationCode} setActivationCode={setActivationCode} />
    ),
    [ModuleTypes.dateTimeCollection]: <CollectionDateForm step={step} prevStep={onPrevStep} nextStep={onNextStep} />,
    [ModuleTypes.thankYou]: <ThankYouForm step={step} />,
    [ModuleTypes.default]: <div>Error: Module Not Found</div>,
  };
};

import React, { useState } from 'react';

// Hooks
import { useFirstModuleFetch } from '../../hooks/useFirstModuleFetch';

// Utils
import { ModuleTypes } from '../../utils/moduleTypes';

// Components
import ActivationCodeForm from '../modules/ActivationCodeForm';
import {
  FormWrapperStyles,
  SpacerStyles,
  ErrorFormWrapperStyles,
  ServerErrorMessageStyles,
  ServerErrorCodeStyles,
} from '../modules/SharedFormComponents/Form/Shared.styles';
import Spinner from '../modules/SharedFormComponents/Spinner';
import ModuleSeriesContainer from '../ModuleSeriesDispatcher';

// Styles
import { PatientPortalFormContainerStyles } from './PatientPortalFormContainer.styles';

const PatientPortalFormContainer = (props: any): JSX.Element => {
  const firstModuleId: string = props.match?.params?.id;
  const [activationCode, setActivationCode] = useState('');
  const [
    {
      state: { firstModule },
      loading,
      error,
    },
  ] = useFirstModuleFetch(firstModuleId);
  const [step, setStep] = useState(1);
  const onNextStep = () => setStep((prevStep: number) => prevStep + 1);

  // Handle serverside error
  if (error) {
    return (
      <PatientPortalFormContainerStyles>
        <ErrorFormWrapperStyles>
          <SpacerStyles height={'110'} width={'40'} />
          <div>
            <ServerErrorCodeStyles>Error</ServerErrorCodeStyles>
            <ServerErrorMessageStyles>{error}</ServerErrorMessageStyles>
          </div>
        </ErrorFormWrapperStyles>
      </PatientPortalFormContainerStyles>
    );
  }

  // Show spinner if loading
  if (loading) {
    return (
      <PatientPortalFormContainerStyles>
        <FormWrapperStyles>
          <SpacerStyles height={'130'} width={'1'} />
          <Spinner />
        </FormWrapperStyles>
      </PatientPortalFormContainerStyles>
    );
  }

  // Show first module for step 1
  if (step === 1) {
    return (
      <PatientPortalFormContainerStyles>
        {(!firstModule || firstModule?.type === ModuleTypes.activationCodeCollection) && (
          <ActivationCodeForm
            step={step}
            nextStep={onNextStep}
            activationCode={activationCode}
            setActivationCode={setActivationCode}
          />
        )}
      </PatientPortalFormContainerStyles>
    );
  }

  // Show next module in the series
  return (
    <PatientPortalFormContainerStyles>
      <ModuleSeriesContainer activationCode={activationCode} setActivationCode={setActivationCode} />
    </PatientPortalFormContainerStyles>
  );
};

export default PatientPortalFormContainer;

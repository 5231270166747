import React, { useState } from 'react';

// Interfaces
type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyboardEvent = React.KeyboardEvent<HTMLDivElement>;

// Images
import ActivationCodeGraphic from '../../../images/Barcode.svg';
import CheckmarkIcon from '../../../images/Checkmark.svg';

// Constants
import { constants } from '../../../utils/constants';
import { ModuleTypes } from '../../../utils/moduleTypes';

// Components & Styled Components
import { WelcomeTextStyles } from '../SharedFormComponents/Form/FormText.styles';
import {
  FormWrapperStyles,
  ErrorMessageStyles,
  ErrorWrapperStyles,
  SpacerStyles,
  SubmittedDataWrapperStyles,
  SubmittedDataHeaderStyles,
} from '../SharedFormComponents/Form/Shared.styles';
import ForwardArrow from '../SharedFormComponents/ForwardArrow';
import ProgressBar from '../SharedFormComponents/ProgressBar';
import { ProgressBarWrapperStyles } from '../SharedFormComponents/ProgressBar/ProgressBar.styles';
import StandardButton from '../SharedFormComponents/StandardButton';
import {
  ActivationCodeInputStyles,
  ActivationCodeGraphicStyles,
  ActivationCodeSpacerStyles,
  ActivationCodeInputGraphicWrapperStyles,
} from './ActivationCodeForm.styles';

const ActivationCodeForm = ({
  nextStep,
  step,
  activationCode,
  setActivationCode,
}: {
  nextStep: () => void;
  step: number;
  activationCode: any;
  setActivationCode: (activationCode: string) => void;
}): JSX.Element => {
  const [error, setError] = useState<string>('');
  const hasSubmittedData = localStorage.getItem(ModuleTypes.activationCodeCollection) !== null;

  const updateActivationCode = (event: InputEvent): void => {
    event.target.value.trim();
    setActivationCode(event.target.value);
  };

  const validateInput = () => {
    if (!activationCode) {
      setError(constants.activationCodeRequiredFieldError);
      return;
    }

    if (activationCode.length >= 64) {
      setError(constants.activationCodeLengthError);
      return;
    }

    // Clientside validation passed, clear clientside error & proceed
    setActivationCode(activationCode);
    setError('');
    nextStep();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      validateInput();
    }
  };

  if (!hasSubmittedData) {
    return (
      <FormWrapperStyles>
        <SpacerStyles height={'10'} width={'1'} />
        <ProgressBar step={step} />
        <SpacerStyles height={'35'} width={'1'} />
        <WelcomeTextStyles>{constants.welcomeText}</WelcomeTextStyles>
        <SpacerStyles height={'45'} width={'1'} />
        <div>{constants.activationCodeFormMainText[0]}</div>
        <div>{constants.activationCodeFormMainText[1]}</div>
        <div>{constants.activationCodeFormMainText[2]}</div>
        {!error && <SpacerStyles height={'20'} width={'1'} />}
        {error && (
          <ErrorWrapperStyles>
            <SpacerStyles height={'10'} width={'1'} />
            <ErrorMessageStyles>{error}</ErrorMessageStyles>
          </ErrorWrapperStyles>
        )}
        <ActivationCodeInputGraphicWrapperStyles>
          <ActivationCodeSpacerStyles />
          <ActivationCodeInputStyles
            type="text"
            defaultValue={activationCode}
            onChange={(event: InputEvent) => updateActivationCode(event)}
            onKeyDown={(event: KeyboardEvent) => onKeyDown(event)}
            error={error}
            placeholder={constants.activationCodePlaceholderText}
          />
          <SpacerStyles height={'1'} width={'10'} />
          <ActivationCodeGraphicStyles src={ActivationCodeGraphic} alt={constants.activationCodeGraphicAltText} />
        </ActivationCodeInputGraphicWrapperStyles>
        <SpacerStyles height={'45'} width={'1'} />
        <StandardButton value={constants.continueButtonText} onClick={() => validateInput()} name={'submit-btn'} />
      </FormWrapperStyles>
    );
  } else {
    return (
      <FormWrapperStyles>
        <ProgressBarWrapperStyles>
          <ProgressBar step={step} />
          <SpacerStyles height={'1'} width={'10'} />
          <ForwardArrow onClick={nextStep} />
        </ProgressBarWrapperStyles>
        <SubmittedDataWrapperStyles>
          <SpacerStyles height={'95'} width={'10'} />
          <SubmittedDataHeaderStyles>
            <SpacerStyles height={'50'} width={'10'} />
            <div>{constants.activationCodeRegisteredText}</div>
            <SpacerStyles height={'1'} width={'10'} />
            <img src={CheckmarkIcon} alt="constants.checkmarkAriaLabelText" />
          </SubmittedDataHeaderStyles>
          <ActivationCodeInputStyles
            type="text"
            defaultValue={JSON.parse(localStorage.getItem(ModuleTypes.activationCodeCollection) as string)}
            error=""
            disabled
          />
        </SubmittedDataWrapperStyles>
      </FormWrapperStyles>
    );
  }
};

export default ActivationCodeForm;

import styled from 'styled-components';
import { media } from '../../../../utils/device';

export const StandardButtonStyles = styled.button`
  background: #4765ff;
  border-radius: 20px;
  color: #ffffff;
  border: 0;
  padding: 4px 30px 7px 30px;
  font-size: 22px;
  height: 42px;
  cursor: pointer;

  ${media.tabletS} {
    width: 320px;
    font-size: 22px;
    padding: 8px 18px 8px 18px;
  }

  ${media.mobileL} {
    width: 280px;
    font-size: 22px;
    padding: 8px 18px 8px 18px;
  }

  ${media.mobileM} {
    width: 250px;
    font-size: 18px;
    padding: 8px 18px 8px 18px;
  }

  ${media.mobileS} {
    width: 225px;
    font-size: 18px;
    padding: 8px 18px 8px 18px;
  }
`;

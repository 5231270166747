import React from 'react';

// Images
import BackArrowImage from '../../../../images/BackArrow.svg';

// Styles
import { BackArrowStyles, BackArrowImageStyles } from './BackArrow.styles';

const BackArrow = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <BackArrowStyles type="button" onClick={onClick} name="back-arrow">
      <BackArrowImageStyles src={BackArrowImage} alt="Back arrow" />
    </BackArrowStyles>
  );
};

export default BackArrow;

import moment from 'moment';
// import { SOME_API_URL } from '../config'
import { ModuleTypes } from '../../../utils/moduleTypes';

// Disabling this for now because we will use Date when this is a real post call
// eslint-disable-next-line
export const handlePostRequest = (date: Date, setServerError: (error: string) => void) => {
  try {
    // Mock post call
    // const endpoint = `${SOME_API_URL}/${a variable amount of data}`
    // const result = await (await fetch(endpoint, {
    //   method: 'POST',
    //   origin: 'x-requested-with',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })).json()
  } catch (error) {
    setServerError(error as string);
  }
};

export const getFormattedSubmitDate = () => {
  const submittedDate = localStorage.getItem(ModuleTypes.dateTimeCollection) as string;
  const formattedDate = moment(submittedDate).format('YYYY-MM-DD hh:mm a');
  return formattedDate;
};

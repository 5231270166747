import React, { useState } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

// Interfaces
type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyboardEvent = React.KeyboardEvent<HTMLDivElement>;
type ChangeEvent = React.ChangeEvent<HTMLSelectElement>;

// Images
import CheckmarkIcon from '../../../images/Checkmark.svg';

// Constants
import { constants } from '../../../utils/constants';
import { ModuleTypes } from '../../../utils/moduleTypes';

// Components & Styled Components
import BackArrow from '../SharedFormComponents/BackArrow';
import {
  FormWrapperStyles,
  ErrorMessageStyles,
  ErrorWrapperStyles,
  SpacerStyles,
  HelperTextStyles,
  SubmittedDataWrapperStyles,
  SubmittedDataHeaderStyles,
  ServerErrorMessageStyles,
  ErrorFormWrapperStyles,
  ServerErrorCodeStyles,
} from '../SharedFormComponents/Form/Shared.styles';
import ForwardArrow from '../SharedFormComponents/ForwardArrow';
import ProgressBar from '../SharedFormComponents/ProgressBar';
import { ProgressBarWrapperStyles } from '../SharedFormComponents/ProgressBar/ProgressBar.styles';
import StandardButton from '../SharedFormComponents/StandardButton';
import StandardButtonSmall from '../SharedFormComponents/StandardButtonSmall';
import {
  CollectionDateInputStyles,
  CollectionMinuteInputStyles,
  CollectionHourInputStyles,
  AmPmSelectStyles,
  DateTimeInputWrapperStyles,
  TimeWrapperStyles,
  AlreadyCollectedTextStyles,
  CollectionTextStyles,
  FullDateTimeInputStyles,
  ToggleManualCollectionButtonStyles,
  DateTimeWrapperStyles,
} from './CollectionDateForm.styles';

// Helpers
import { handlePostRequest, getFormattedSubmitDate } from './helpers';

const CollectionDateForm = ({
  step,
  prevStep,
  nextStep,
}: {
  step: number;
  prevStep: () => void;
  nextStep: () => void;
}): JSX.Element => {
  const [error, setError] = useState('');
  const [allDateData, setAllDateData] = useState<{
    date: Date | undefined;
    hour: number | undefined;
    minute: number | undefined;
    ampm: string;
  }>({
    date: undefined,
    hour: undefined,
    minute: undefined,
    ampm: 'am',
  });
  const [serverError, setServerError] = useState('');
  const [showManualEntry, setShowManualEntry] = useState(false);
  const hasSubmittedData = localStorage.getItem(ModuleTypes.dateTimeCollection) !== null;

  const validateInput = (): void => {
    // Validate date format
    if (allDateData.date === undefined) {
      setError(constants.dateFormatErrorText);
      return;
    }

    if (allDateData.hour === 0 || allDateData.hour === undefined) {
      setError(constants.hourIsRequiredText);
      return;
    }

    if (allDateData.hour >= 13 || allDateData.hour < 1) {
      setError(constants.hourRangeError);
      return;
    }

    if (allDateData.minute === 0 || allDateData.minute === undefined) {
      setError(constants.minuteIsRequiredText);
      return;
    }

    if (allDateData.minute >= 60 || allDateData.minute < 0) {
      setError(constants.minuteRangeError);
      return;
    }

    // Validation passed
    // Clear error, update state & proceed
    setError('');

    // Convert 12 hour time to 24 hour time
    const validatedTime = moment(
      allDateData.hour + ':' + allDateData.minute + ' ' + allDateData.ampm,
      'hh:mm A'
    ).format('HH:mm');

    // Create moment object date and set 24 hour time on moment date
    const momentDate = moment(allDateData.date);
    const momentTime = moment(validatedTime, 'HH:mm');
    momentDate.set('hour', momentTime.get('hours'));
    momentDate.set('minute', momentTime.get('minutes'));

    // Date is validated, let's post it to the database
    handleModuleSubmit(momentDate.toDate());
  };

  const handleModuleSubmit = (date: Date) => {
    handlePostRequest(date, setServerError);

    // Check for serverside post error
    // If one exists, show the error, else, proceed
    if (!serverError) {
      // Post worked without error, save data to local storage & proceed
      // We save the date in the end user's timezone in local storage
      // So it displays as the end user expects in the UI
      const localStorageDate = moment(date);
      localStorage.setItem(ModuleTypes.dateTimeCollection, localStorageDate.toString());
      nextStep();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      validateInput();
    }
  };

  const handleTimeInputChange = (event: InputEvent | ChangeEvent) => {
    const value = event.target.value;
    setAllDateData({
      ...allDateData,
      [event.target.name]: value,
    });
  };

  const getMinuteDefaultValue = () => {
    // Adding zero to minute for display
    if (allDateData.minute !== undefined) {
      return (allDateData.minute < 10 ? '0' : '') + allDateData.minute;
    }
    return allDateData.minute;
  };

  const getDisabledDaysRange = () => {
    // Get current day Date object
    const startingDate = new Date();
    // Get day number for one week ago
    const oneWeekAgoDay = startingDate.getDate() - 7;
    // Set starting date object to one week ago day
    startingDate.setDate(oneWeekAgoDay);

    return {
      before: startingDate,
      after: new Date(),
    };
  };

  const handleToggleManualCollection = () => {
    setShowManualEntry((showing) => !showing);
  };

  // Show server error if post request fails
  if (serverError) {
    return (
      <ErrorFormWrapperStyles>
        <SpacerStyles height={'110'} width={'40'} />
        <div>
          <ServerErrorCodeStyles>Error</ServerErrorCodeStyles>
          <ServerErrorMessageStyles>{Error}</ServerErrorMessageStyles>
        </div>
      </ErrorFormWrapperStyles>
    );
  }

  if (!hasSubmittedData) {
    return (
      <FormWrapperStyles onKeyDown={(event: KeyboardEvent) => handleKeyDown(event)}>
        <ProgressBarWrapperStyles>
          <BackArrow onClick={prevStep} />
          <SpacerStyles height={'1'} width={'10'} />
          <ProgressBar step={step} />
        </ProgressBarWrapperStyles>
        <SpacerStyles height={'80'} width={'1'} />
        <CollectionTextStyles>{constants.readyToCollectText}</CollectionTextStyles>
        <SpacerStyles height={'5'} width={'1'} />
        <StandardButton
          value={constants.logCollectionTimeNowButtonText}
          onClick={() => handleModuleSubmit(new Date())}
          name={'collect-now-btn'}
        />
        <SpacerStyles height={'70'} width={'1'} />
        <AlreadyCollectedTextStyles>{constants.alreadyCollectedText}</AlreadyCollectedTextStyles>
        <SpacerStyles height={'5'} width={'1'} />
        {!showManualEntry && (
          <ToggleManualCollectionButtonStyles
            onClick={() => handleToggleManualCollection()}
            name={'toggle-manual-collection'}
          >
            {constants.toggleManualCollectionText}
          </ToggleManualCollectionButtonStyles>
        )}
        {showManualEntry && (
          <DateTimeWrapperStyles>
            <HelperTextStyles>{constants.enterCollectionText}</HelperTextStyles>
            {!error && <SpacerStyles height={'14'} width={'1'} />}
            {error && (
              <ErrorWrapperStyles>
                <SpacerStyles height={'2'} width={'1'} />
                <ErrorMessageStyles>{error}</ErrorMessageStyles>
              </ErrorWrapperStyles>
            )}
            <DateTimeInputWrapperStyles>
              <div>
                <DayPickerInput
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  value={allDateData.date}
                  onDayChange={(day) => {
                    setAllDateData({ ...allDateData, date: day });
                  }}
                  formatDate={MomentLocaleUtils.formatDate}
                  parseDate={MomentLocaleUtils.parseDate}
                  inputProps={{ error: error, ref: null, readOnly: true }}
                  dayPickerProps={{ disabledDays: getDisabledDaysRange() }}
                  component={(props: any) => {
                    return <CollectionDateInputStyles {...props} onKeyUp={() => validateInput()} />;
                  }}
                />
              </div>
              <SpacerStyles height={'2'} width={'1'} />
              <TimeWrapperStyles>
                <SpacerStyles height={'1'} width={'2'} />
                <CollectionHourInputStyles
                  type="number"
                  onChange={(event: InputEvent) => handleTimeInputChange(event)}
                  defaultValue={allDateData.hour}
                  placeholder="HH"
                  error={error}
                  name="hour"
                  title={constants.hourCollectionText}
                  min={1}
                  max={12}
                />
                <div>:</div>
                <CollectionMinuteInputStyles
                  type="number"
                  onChange={(event: InputEvent) => handleTimeInputChange(event)}
                  defaultValue={getMinuteDefaultValue()}
                  placeholder="MM"
                  error={error}
                  name="minute"
                  title={constants.minuteCollectionText}
                  min={0}
                  max={59}
                />
                <SpacerStyles height={'1'} width={'3'} />
                <AmPmSelectStyles
                  error={error}
                  name="ampm"
                  defaultValue={allDateData.ampm}
                  title={constants.amPmCollectionText}
                  onChange={(event: ChangeEvent) => handleTimeInputChange(event)}
                >
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </AmPmSelectStyles>
              </TimeWrapperStyles>
            </DateTimeInputWrapperStyles>
            <SpacerStyles height={'5'} width={'1'} />
            <div>
              <StandardButtonSmall
                value={constants.submitButtonText}
                onClick={() => validateInput()}
                name={'submit-btn'}
              />
            </div>
          </DateTimeWrapperStyles>
        )}
      </FormWrapperStyles>
    );
  } else {
    return (
      <FormWrapperStyles onKeyDown={(event: KeyboardEvent) => handleKeyDown(event)}>
        <ProgressBarWrapperStyles>
          <BackArrow onClick={prevStep} />
          <SpacerStyles height={'1'} width={'10'} />
          <ProgressBar step={step} />
          <SpacerStyles height={'1'} width={'10'} />
          <ForwardArrow onClick={nextStep} />
        </ProgressBarWrapperStyles>
        <SubmittedDataWrapperStyles>
          <SpacerStyles height={'95'} width={'10'} />
          <SubmittedDataHeaderStyles>
            <SpacerStyles height={'50'} width={'10'} />
            <div>{constants.collectionTimeSubmittedText}</div>
            <SpacerStyles height={'1'} width={'10'} />
            <img src={CheckmarkIcon} alt="constants.checkmarkAriaLabelText" />
          </SubmittedDataHeaderStyles>
          <FullDateTimeInputStyles value={getFormattedSubmitDate()} disabled />
        </SubmittedDataWrapperStyles>
      </FormWrapperStyles>
    );
  }
};

export default CollectionDateForm;

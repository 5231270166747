import React from 'react';

// Images
import TassoLogo from '../../../../images/TassoLogo.svg';

// Styles
import { HeaderStyles, LogoStyles } from './Header.styles';

const Header = (): JSX.Element => {
  return (
    <HeaderStyles>
      <LogoStyles src={TassoLogo} alt="Tasso Logo" />
    </HeaderStyles>
  );
};

export default Header;

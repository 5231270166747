import React from 'react';

// Constants
import { constants } from '../../../utils/constants';

// Components & Styled Components
import { ThankYouTextStyles } from '../SharedFormComponents/Form/FormText.styles';
import { FormWrapperStyles, SpacerStyles, ThankYouSmallTextStyles } from '../SharedFormComponents/Form/Shared.styles';
import ProgressBar from '../SharedFormComponents/ProgressBar';

const ThankYouForm = ({ step }: { step: number }): JSX.Element => {
  return (
    <div>
      <FormWrapperStyles>
        <SpacerStyles height={'10'} width={'1'} />
        <ProgressBar step={step} />
        <SpacerStyles height={'105'} width={'1'} />
        <ThankYouTextStyles data-test-id="thank-you-text">{constants.thankYouText}</ThankYouTextStyles>
        <SpacerStyles height={'35'} width={'1'} />
        <ThankYouSmallTextStyles data-test-id="thank-you-text-instructions-one">
          {constants.thankYouInstructionsLineOne}
        </ThankYouSmallTextStyles>
        <ThankYouSmallTextStyles data-test-id="thank-you-text-instructions-two">
          {constants.thankYouInstructionsLineTwo}
        </ThankYouSmallTextStyles>
      </FormWrapperStyles>
    </div>
  );
};

export default ThankYouForm;

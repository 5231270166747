import styled from 'styled-components';
import { media } from '../../../utils/device';

interface Props {
  error: string;
}

export const ActivationCodeInputStyles = styled.input`
  height: 50px;
  background: #f6f6f6;
  border: ${(props: Props) => (props.error ? '2px solid #ff0000' : '1px solid #eaeaea')};
  font-size: 26px;
  line-height: 100%;
  letter-spacing: 0.1em;
  width: 315px;
  text-align: center;

  ${media.tabletS} {
    width: 420px;
    font-size: 20px;
  }

  ${media.mobileL} {
    width: 280px;
  }

  ${media.mobileM} {
    width: 245px;
  }

  ${media.mobileS} {
    width: 240px;
  }
`;

export const ActivationCodeInputGraphicWrapperStyles = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
`;

export const ActivationCodeGraphicStyles = styled.img`
  ${media.tabletS} {
    display: none;
  }
`;

export const ActivationCodeSpacerStyles = styled.div`
  width: 150px;

  ${media.tabletS} {
    width: 10px;
  }
`;

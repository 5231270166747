import styled from 'styled-components';

export const BackArrowStyles = styled.button`
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0px;
`;
export const BackArrowImageStyles = styled.img`
  height: 30px;
  margin-top: 5px;
`;

import { useState, useEffect, useCallback } from 'react';
import { mockModuleSeriesObject } from '../utils/mockServersideJson';
import { ModuleTypes } from '../utils/moduleTypes';
// import { SOME_API_URL } from '../config'

// Interfaces
interface Module {
  module_id: string;
  type: string;
  next: string;
  altNext: string;
  prev: string;
}

interface State {
  patient_project_id: string;
  project_id: string;
  customer_id: string;
  device_id: string;
  patient_id: string;
  modules: Array<Module>;
}

export const useModulesFetch = (activationCode: string) => {
  const [state, setState] = useState<State>({
    patient_project_id: '',
    project_id: '',
    customer_id: '',
    device_id: '',
    patient_id: '',
    modules: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchModules = useCallback(async () => {
    setError('');
    setLoading(true);

    try {
      // In the future this will be a real endpoint
      // That will give us a real JSON object
      // That represents the configuration of the form modules
      // Until then, we will mock this data

      // Get all modules
      // const endpoint = `${SOME_API_URL}/${activationCode}`
      // const result = await (await fetch(endpoint, {
      //   method: 'GET',
      //   origin: 'x-requested-with',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })).json()

      setState(mockModuleSeriesObject);
    } catch (error) {
      setError(error as string);
    }
    setLoading(false);
  }, [activationCode]);

  useEffect(() => {
    if (localStorage['useModulesFetch']) {
      setState(JSON.parse(localStorage['useModulesFetch']));
      setLoading(false);
    } else {
      fetchModules();
    }
  }, [fetchModules, activationCode]);

  useEffect(() => {
    if (activationCode) {
      localStorage.setItem(ModuleTypes.activationCodeCollection, JSON.stringify(activationCode));
    }
    localStorage.setItem('useModulesFetch', JSON.stringify(state));
  }, [activationCode, state]);

  return [{ state, loading, error }];
};

import React from 'react';

// Images
import ForwardArrowImage from '../../../../images/BackArrow.svg';

// Styles
import { ForwardArrowStyles, ForwardArrowImageStyles } from './ForwardArrow.styles';

const ForwardArrow = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <ForwardArrowStyles type="button" onClick={onClick} name="Forward-arrow">
      <ForwardArrowImageStyles src={ForwardArrowImage} alt="Forward arrow" />
    </ForwardArrowStyles>
  );
};

export default ForwardArrow;

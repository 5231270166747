import styled from 'styled-components';
import { media } from '../../../../utils/device';

export const WelcomeTextStyles = styled.div`
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ThankYouTextStyles = styled.div`
  font-size: 42px;
  text-align: center;

  ${media.tabletS} {
    font-size: 33px;
  }
`;

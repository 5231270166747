import 'reflect-metadata';

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Components
import Header from './components/modules/SharedFormComponents/Header';
import PatientPortalFormContainer from './components/PatientPortalFormContainer';

// Styles
import { GlobalStyles } from './styles/GlobalStyles';

function App() {
  return (
    <div>
      <GlobalStyles />
      <Header />
      <Router>
        <Switch>
          {/* We want to support variable param data 
          in the URL on the landing page */}
          <Route path="/:id">
            <PatientPortalFormContainer />
          </Route>
          <Route path="/">
            <PatientPortalFormContainer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import { useState, useEffect, useCallback } from 'react';
// import { SOME_API_URL } from '../config'

// Utils
import { mockFirstModuleObject } from '../utils/mockServersideJson';
import { ModuleTypes } from '../utils/moduleTypes';

// Interfaces
interface Module {
  module_id: string;
  type: string;
  next: string;
  altNext: string;
  prev: string;
}

interface State {
  firstModule: Module;
}

export const useFirstModuleFetch = (linkData: string) => {
  const [state, setState] = useState<State>({
    firstModule: {
      module_id: '123456',
      type: ModuleTypes.activationCodeCollection,
      next: '234567',
      altNext: '',
      prev: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchFirstModule = useCallback(async () => {
    setError(false);
    setLoading(true);

    try {
      // In the future this will be a real endpoint
      // That will give us a real JSON object
      // That will help decide which module to render as the first form module
      // That the end user will see when they click a custom or generic link
      // We want to account for custom links with data we could use to customize
      // the first module, as well as a generic link without any custom data
      // Until then, we will mock this api call with a JSON object

      // const endpoint = `${SOME_API_URL}/${a variable amount of data}`
      // const result = await (await fetch(endpoint, {
      //   method: 'GET',
      //   origin: 'x-requested-with',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })).json()

      setState(mockFirstModuleObject);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [linkData]);

  useEffect(() => {
    if (localStorage['firstModuleFetch']) {
      setState(JSON.parse(localStorage['firstModuleFetch']));
      setLoading(false);
    } else {
      fetchFirstModule();
    }
  }, [fetchFirstModule]);

  useEffect(() => {
    localStorage.setItem('firstModuleFetch', JSON.stringify(state));
  }, [state]);

  return [{ state, loading, error }];
};

import React, { useState } from 'react';

// Hooks
import { useModulesFetch } from '../../hooks/useModulesFetch';

// Components
import { FormWrapperStyles, SpacerStyles } from '../modules/SharedFormComponents/Form/Shared.styles';
import Spinner from '../modules/SharedFormComponents/Spinner';

// Helpers
import { findNextModule, getModulesDispatcher } from './helpers';

const ModuleSeriesDispatcher = ({
  activationCode,
  setActivationCode,
}: {
  activationCode: string;
  setActivationCode: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const [
    {
      state: { modules },
      loading,
      error,
    },
  ] = useModulesFetch(activationCode);
  // First module has already been shown
  // We want to show the second module in the series
  const [step, setStep] = useState(2);

  const onNextStep = () => setStep((prevStep: number) => prevStep + 1);
  const onPrevStep = () => setStep((prevStep: number) => prevStep - 1);

  // Handle serverside errors & loading
  if (error) {
    return (
      <FormWrapperStyles>
        <SpacerStyles height={'130'} width={'1'} />
        <div>Error: {error}</div>
      </FormWrapperStyles>
    );
  }

  if (!modules || modules.length === 0) {
    return (
      <FormWrapperStyles>
        <SpacerStyles height={'130'} width={'1'} />
        <div> Error: No modules! </div>
      </FormWrapperStyles>
    );
  }

  if (loading) {
    return (
      <FormWrapperStyles>
        <SpacerStyles height={'130'} width={'1'} />
        <Spinner />
      </FormWrapperStyles>
    );
  }

  const moduleTypeToComponent: any = getModulesDispatcher({
    step,
    onPrevStep,
    onNextStep,
    activationCode,
    setActivationCode,
  });

  // Get and show next module if one exists
  const nextModule = findNextModule(modules, step);
  if (!nextModule) return <div>Error: Module Not Found</div>;
  return moduleTypeToComponent[nextModule];
};

export default ModuleSeriesDispatcher;

import React from 'react';

// Images
import TassoLogoSpinner from '../../../../images/TassoLoadingLogo.gif';

// Styles
import { StyledSpinnerLogo } from './Spinner.styles';

const Spinner = () => <StyledSpinnerLogo src={TassoLogoSpinner} />;

export default Spinner;

import { ModuleTypes } from '../utils/moduleTypes';

export const mockFirstModuleObject = {
  firstModule: {
    module_id: '123456',
    type: ModuleTypes.activationCodeCollection,
    next: '234567',
    altNext: '',
    prev: '',
  },
};

export const mockModuleSeriesObject = {
  patient_project_id: 'uuid',
  project_id: 'uuid',
  customer_id: 'uuid',
  device_id: 'uuid',
  patient_id: 'uuid',
  modules: [
    { module_id: '123456', type: ModuleTypes.activationCodeCollection, next: '234567', altNext: '', prev: '' },
    { module_id: '234567', type: ModuleTypes.dateTimeCollection, next: '345678', altNext: '', prev: '123456' },
    { module_id: '345678', type: ModuleTypes.thankYou, next: '', altNext: '', prev: '234567' },
  ],
};

export const mockModulePostObject = {
  patient_project_response_id: 'uuid', // generated on post
  patient_project_id: 'uuid',
  project_id: 'uuid',
  customer_id: 'uuid',
  device_id: 'uuid',
  patient_id: 'uuid',
  module_id: 'uuid',
  response: { activationCode: 'test activationCode' }, // flexible JSON object
};
